
<template>
    <!-- 左侧二级菜单栏的组件封装 -->
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            @open="handleOpen"
            :default-active="toIndex"
            background-color="#fff"
            text-color="#000000"
            @select="test"
            router
        >
            <el-submenu v-for="(item, index) in itemList" :key="index" :index="item.path">
                <i :class="item.icon"></i>
                <template slot="title">
                    <div
                        @click="item.children ? title_active(index, item.title) : torouter(item.path, index)"
                        :class="item.title_show ? 'item_title' : ''"
                    >
                        {{ item.title }}
                        <span class="icoItem">
                            <i v-if="item.children" id="actico" :class="item.show ? 'up' : 'down'" class="el-icon-arrow-down"></i>
                        </span>
                    </div>
                </template>
                <el-menu-item-group v-for="(child, childItem) in item.children" :key="childItem">
                    <el-menu-item :index="child.path" @click="show_title">
                        {{ child.title }}
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </div>
</template>

<script>
export default {
    props: {
        itemList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            key: '',
            keyPath: ''
        };
    },
    computed: {
        toIndex() {
            // 根据路径绑定到对应的二级菜单，防止页面刷新重新跳回第一个
            return this.$route.path.split('/')[2];
        }
    },
    mounted() {
        //页面路径
        let url = window.location.href;
        let lastindex = url.split('#')[1].lastIndexOf('/');
        let index_url = url.split('#')[1].slice(lastindex + 1);
       // console.log(index_url);
        this.for_EACH(); //掉一下循环
        // 判断刷新的时候
        if (url.indexOf(index_url) != -1) {
            setTimeout((v) => {
                this.itemList.forEach((item) => {
                    // 子集图标旋转  如果是在子集 就让下边成up 当成show = true
                    if (item.children) {
                        item.children.forEach((child) => {
                            if (child.path == index_url) item.show = true;
                           // console.log(index_url);
                        });
                    } else {
                        // 父级高亮 在父级
                        if (item.path == index_url) item.title_show = true;
                    }
                });
                this.$forceUpdate();
            }, 100);
        } else {
            // 从其他页面过来的情况 让当前对应的父级页面title高亮
            let title = localStorage.getItem('title');

            setTimeout((v) => {
                if (title == '/Data') {
                    //当前资料管理页面对应的侧边子集高亮
                    this.itemList[0].title_show = true;
                    this.$forceUpdate();
                } else if (title == '/User') {
                    this.itemList[0].title_show = true;
                    this.$forceUpdate();
                }
            }, 100);
        }
    },
    methods: {
        // 循环
        for_EACH() {
            // 设置一进来的默认值
            this.itemList.forEach((item, index) => {
                item.title_show = false; //高亮
                item.show = false; //图标
              //  console.log(item);
                if (item.children) {
                    item.children.forEach((children) => {
                        children.child_show = false;
                    });
                }
            });
        },
        // 子集事件
        show_title() {
            // 清空父级高亮
            this.itemList.forEach((item) => (item.title_show = false));

            this.$forceUpdate();
        },
        // 有子集
        title_active(index) {
            // 当前图标旋转
            this.itemList[index].show = !this.itemList[index].show;
            // 清空父级高亮
            this.itemList.forEach((item) => (item.title_show = false));
            this.$forceUpdate();
        },
        // 没有子集
        torouter(path, index) {
            // 当前title得高亮  当循环的下标等于当前的下标  就说明是当前 得高亮true  其他情况不能高亮false
            this.itemList.forEach((item, for_index) => {
                if (index == for_index) {
                    item.title_show = true;
                } else {
                    item.title_show = false;
                }
            });
            this.$forceUpdate();
            this.$router.push(path);
        },
        handleOpen(key, keyPath) {
           // console.log(key, keyPath);
            if (key == keyPath[0]) {
                this.key = key;
                this.keyPath = keyPath[0];
            }
        },
        test(index, path) {
            // console.log(path[1]);

            if (path[1] == 'video') {
                window.location.href = 'https://meeting.tencent.com/user-center/join';
                // 跳转到user.html 页面并清除当前页面的history记录
                window.history.replaceState(null, '', 'https://meeting.tencent.com/user-center/join');
                window.history.go(0);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
/* 左侧菜单栏定位和位置大小设定 */
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu {
    width: 300px;
}
.sidebar > ul {
    height: 100%;
}

/* 左侧二级菜单项的样式 */
.el-menu-item {
    font-size: 14px !important;
    padding-left: 35px !important;
}

/* 左侧二级菜单选中时的样式 */
.el-menu-item.is-active {
    /* color: white !important;
    background: #888f99 !important; */
    color: #3989fa;
    font-weight: bold;
}
.el-menu-item,
.el-submenu__title {
    height: 50px !important;
    line-height: 50px !important;
}
::v-deep .sidebar .el-submenu__icon-arrow .el-icon-arrow-down {
    display: none;
    content: '';
}
.item_title {
    width: 100%;
    color: #409eff;
}

.el-icon-arrow-down {
    transform: rotate(90deg);
    position: absolute;
    right: 50px;
    top: 19px;
    font-size: 5px;
}
.up {
    animation: up 0.1s forwards;
}
@keyframes up {
    0% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(45deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.down {
    animation: down 0.1s forwards;
}
@keyframes down {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(45deg);
    }
    100% {
        transform: rotate(90deg);
    }
}
</style>